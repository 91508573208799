$color-black: #191919;
$color-white: #ffffff;
$color-gray: #484848;
$font-size: 14px;
$font-size-title: 24px;
$font-size-button: 12px;
$font-family: "Avenir";
$font-family-title: "Georgia";

$transition: 0.3s;
$space: 40px;


html, body {
  margin: 0 auto;
  padding: 0;
  font-family: $font-family;
  font-size: $font-size;
  line-height: 170%;
  color: $color-gray;
}

.container {
  text-align: center;
  max-width: 480px;
  padding-left: 30px;
  padding-right: 30px;
  margin: 50px auto;
  box-sizing: border-box;
}


p {
  margin-bottom: $space / 2;
}

h1 {
  color: $color-black;
  margin-top: 0;
  padding-top: 0;
  font-size: $font-size-title;
  font-family: $font-family-title;
  line-height: 140%;
  font-weight: normal;

  @media (max-width: 260px) {
    br {
      display: none;
    }
  }

}
.title {
  padding: 0;
  margin: 0;
}

.btn {
  position: relative;
  box-sizing: border-box;
  transition: $transition;
  display: block;
  padding: 10px 15px;
  width: 100%;
  font-size: $font-size-button;
  background: $color-black;
  color: $color-white;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1.8px;

  &-arrow:after {
    content: "→";
    position: absolute;
    right: 15px;
  }

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  &:hover,
  &:active {
    opacity: .9;
  }
}



.header {

  &-logo {
    transition: $transition;
    display: inline-block;
    margin-bottom: $space / 1.33;
  }
  &:hover,
  &:active {
    opacity: .9;
  }

  &-nav {
    margin-bottom: $space / 1.33;
  }

}

.social-icons {
  margin-bottom: $space;

  &__item {
    text-decoration: none;
    margin: 0 4px 4px;

    &:hover {
      color: $color-black
    }
  }

}
